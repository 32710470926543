import { IdsIcon } from '@ids/react'

import './additional-info.scss'

const AdditionalInfo = () => {
  return (
    <div>
      <ul className="additional-info" id="info">
        <li className="additional-info__item">
          <IdsIcon variant="neutral" className="additional-info__icon">
            aviso_outline
          </IdsIcon>
          <p className="additional-info__text">
            Ao continuar, tenha disponível um documento com foto (CNH, RG ou
            RNE)
          </p>
        </li>
        <li className="additional-info__item">
          <IdsIcon variant="neutral" className="additional-info__icon">
            trancado_outline
          </IdsIcon>
          <p className="additional-info__text">
            Essa etapa vai ser feita pelo seu celular por motivos de segurança
            dos seus dados.
          </p>
        </li>
      </ul>
    </div>
  )
}

export default AdditionalInfo
