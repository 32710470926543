import { IdsMainButton } from '@ids/react'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import { SeeOffersButton } from '@itau-loans-www/shopping/src/components'
import Layout from '@itau-loans-www/shopping/src/layouts/Summary'
import analytics from '@mobi/libraries/analytics'

import AdditionalInfo from '../components/AdditionalInfo'
import '../final-screen.scss'

import { useResult } from '../hooks'

const Reneg = () => {
  const proposalContext = useProposal()
  const link = proposalContext?.product?.proposal?.link
  const productCode = proposalContext?.product?.proposal?.productCode

  useResult({})

  return (
    <Layout>
      <div className="final_screen">
        <div className="final_screen__content">
          <h1 className="final_screen__title">
            Acesse a página Renegocie Fácil
          </h1>

          <p className="final_screen__text">
            Continue a sua simulação no Renegocie Fácil para renegociar a sua
            dívida com a ajuda do Itaú
          </p>

          <div className="final_screen__buttons-wrapper">
            <a href={link} target="_blank" rel="noreferrer">
              <IdsMainButton
                full={true}
                onClick={() => {
                  analytics.track('buttonClick', {
                    currentPage: 'telas-finais-de-redirect-do-produto',
                    detail: productCode
                  })
                }}
              >
                Acessar a página
              </IdsMainButton>
            </a>
            <SeeOffersButton isCrossSell />

            <AdditionalInfo />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Reneg
